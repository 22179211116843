import React from 'react'
import MemoTab from './MemoTab'
import useAdminData from 'hooks/useAdminData'
import LPHMemoTab from './LPHMemoTab'
import ExecutiveMemoTab from './ExecutiveMemoTab'
// import { Spin } from 'antd'
import FullScreenLoader from '../FullScreenLoader'

const MemoComp = () => {
  const {adminRole,isLoading} = useAdminData()

  if(isLoading){
    return <FullScreenLoader/>
  }

  if(adminRole === "lph") {
    return <LPHMemoTab/>
  }
  if(adminRole === "es") {
    return <ExecutiveMemoTab/>
  }
  // if(adminRole === "ol") {
  //   return <ExecutiveMemoTab/>
  // }
  return (
    <MemoTab />
  )
}

export default MemoComp