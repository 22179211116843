import React from "react"
import dayjs from "dayjs"
import CommaNumber from "comma-number"
import { CSVLink } from "react-csv"
import { DownCsvBtn, Loandetails, TableDetailTop, TransTableBody, TransTableContainer, TransTableContent } from "./style"
import { sumData } from "utils"
// import EmptyDataUi from "components/EmptyDoc"
import { Div } from "globalStyles"
// import SendMemoComp from "components/Dashboard/Loan/SendMemoComp"
import { useState } from "react"
import Tabledetails from "./LoanDetailstable"
import {CheckBox} from "components/Checkbox"
import { useNavigate } from "react-router-dom"

function LoanAppTable({ tableData, title = "", role,selectedLoans,selectLoan }) {
	const navigate = useNavigate()
	const filteredLoan = tableData.filter(loan=>!loan.esLoans)

	const goToLoanDetails = (id)=>{
		navigate(`/loan/loan-details/${id}`)
	}
	const [show, setShow] = useState(false)
	const [selected, setSelected] = useState({})

	function calculate_age(dob) {
		const g = new Date(dob)
		var diff_ms = Date.now() - g.getTime()
		var age_dt = new Date(Date.now() - diff_ms)
		return Math.abs(age_dt.getUTCFullYear() - new Date().getFullYear())
	}
	const showDetails = payload => {
		setShow(true)
		setSelected(payload)
	}
	const closeDetails = () => {
		setShow(false)
		setSelected({})
	}
	const totalAmountApproved = tableData?.length > 0 ? tableData?.map(data => data?.amount) : [0]
	const amountTotal = sumData(totalAmountApproved)
	const xslcdata =
		filteredLoan?.length > 0
			? filteredLoan?.map((loan, idx) => {
					return {
						sn: idx + 1,
						name: `${loan?.bank_account?.account_name.toUpperCase()}`,
						phone: `'${loan?.applicant?.phone}`,
						institution: loan?.institution?.name ? loan?.institution?.name?.toUpperCase() : "",
						level: loan?.institution?.current_study_year ? loan?.institution?.current_study_year?.toUpperCase() : "",
						course: loan?.institution?.course ? loan?.institution?.course?.toUpperCase() : "",
						amount: loan?.amount ? `${CommaNumber(loan?.amount)}.00` : `0.00`,
						insurance: `${CommaNumber((1 / 100) * loan?.amount)}.00`,
						net_amount: `${CommaNumber(loan?.amount - (1 / 100) * loan?.amount)}.00`,
						comment: loan?.request_status ? loan?.request_status.toUpperCase() : "",
						bank: loan?.bank_account?.bank_name ? loan?.bank_account?.bank_name?.toUpperCase() : "",
						account_number: `'${loan?.bank_account?.account_number}`,
						file_id: `${loan?.id?.toUpperCase()}`,
						age: calculate_age(loan?.applicant?.dob),
						guarantor_one: `${loan?.guarantor_one?.surname?.toUpperCase()} ${loan?.guarantor_one?.first_name?.toUpperCase()} ${loan?.guarantor_one?.middle_name?.toUpperCase()}`,
						guarantor_one_phone: `'${loan?.guarantor_one?.phone} `,
						guarantor_one_work_place: `${loan?.guarantor_one?.work_place}`,
						guarantor_two: `${loan?.guarantor_two?.surname?.toUpperCase()} ${loan?.guarantor_two?.first_name?.toUpperCase()} ${loan?.guarantor_two?.middle_name?.toUpperCase()}`,
						guarantor_two_phone: `'${loan?.guarantor_two?.phone} `,
						guarantor_two_work_place: `${loan?.guarantor_two?.work_place}`,
						disburse_date: loan?.disburse_date ? `${dayjs(loan?.disburse_date).format("MMM D, YYYY")}` : "",
						monthly_repayment_amount: `${CommaNumber(loan?.monthly_repayment_amount)}`,
						repayment_period_starts: loan?.repayment_period_starts ? `${dayjs(loan?.repayment_period_starts).format("MMM D, YYYY")}` : ""
					}
			  })
			: []

	const totalNetAmount = tableData?.length > 0 ? tableData?.map(data => (1 / 100) * data?.amount) : [0]
	const totalMonthlyRepaymentAmount = tableData?.length > 0 ? tableData?.map(data => data?.monthly_repayment_amount) : [0]
	const amountNetTotal = amountTotal - sumData(totalNetAmount)
	const amouninsuranceTotal = sumData(totalNetAmount)
	const monthlyRem = sumData(totalMonthlyRepaymentAmount)
	const hold_data = [
		[
			"S/N",
			"NAME",
			"PHONE",
			"INSTITUTION",
			"LEVEL",
			"COURSE",
			"AMOUNT",
			"INSURANCE",
			"NET AMOUNT",
			"COMMENT",
			"BANK",
			"ACCOUNT NUMBER",
			"FILE ID",
			"AGE",
			"FIRST GUARANTOR",
			"PHONE",
			"WORK PLACE",
			"SECOND GUARANTOR",
			"PHONE",
			"WORK PLACE",
			"DATE DISBURSED",
			"MONTHLY REPAYMENT AMOUNT",
			"MONTHLY REPAYMENT STARTS"
		]
	]
	const emptyrow_data = [["", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]]
	const total_data = [
		[
			"TOTAL",
			"",
			"",
			"",
			"",
			"",
			`${CommaNumber(amountTotal)}.00`,
			`${CommaNumber(amouninsuranceTotal)}.00`,
			`${CommaNumber(amountNetTotal)}.00`,
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			"",
			monthlyRem ? `${CommaNumber(monthlyRem)}` : "",
			"",
			""
		]
	]
	const mappedArray = xslcdata.length > 0 ? xslcdata?.map(obj => Object.values(obj)) : []
	const concatenatedArr = [].concat(hold_data, mappedArray, emptyrow_data, total_data)

	return (
		<>
			{show && (
				<Loandetails show={show}>
					<Tabledetails payload={selected} close={closeDetails} />
				</Loandetails>
			)}
			<TransTableContent>
				<TableDetailTop>
					<h5>{title}</h5>
					<Div display="flex" alignI="center">
						{filteredLoan?.length > 0 && filteredLoan[0]?.status === "disbursed" ? (
							<>
								{role === "acc" ? (
									<CSVLink style={{ marginTop: "5px", marginRight: "10px" }} filename={`${dayjs(tableData[0]?.dis).format("MMM D, YYYY")}${title?.toLowerCase()}.csv`} data={concatenatedArr}>
										<DownCsvBtn>Download CSV</DownCsvBtn>
									</CSVLink>
								) : role === "lph" ? (
									<CSVLink filename={`${title?.toLowerCase()}.csv`} data={concatenatedArr}>
										<DownCsvBtn>Download CSV</DownCsvBtn>
									</CSVLink>
								) : null}
							</>
						) : null}
						<span style={{ marginLeft: "10px" }}></span>
						{/* {role === "es" || role === "lph" || role === "la" ? (
							<div style={{ marginTop: "10px" }}>{
								<SendMemoComp title="Forward A Memo" disabled={tableData?.length > 0 && tableData[0]?.status === "disbursed"} />}
								</div>
						) : null} */}
					</Div>
				</TableDetailTop>
				<TransTableContainer>
					<TransTableContent>
						<TransTableBody>
							<tr>
								{filteredLoan?.length > 1 && <th>Pick</th>}
								<th>S/N</th>
								<th>
									<Div display="flex" alignI="center">
										INSTITUTION
									</Div>
								</th>
								<th>
									<Div display="flex" alignI="center">
										FACULTY
									</Div>
								</th>
								<th>
									<Div display="flex" alignI="center">
										COURSE OF STUDY
									</Div>
								</th>
								<th>STUDY LEVEL</th>
								<th>LOAN TYPE</th>
								<th>A/C NAME</th>
								<th>A/C NUMBER</th>
								<th>BANK</th>
								<th>AMOUNT</th>
								<th>PREVIEW...</th>
								<th>MORE...</th>
							</tr>
							{filteredLoan?.map((loan, i) => (
								<tr key={loan?._id}>
									{tableData?.length > 1 && (
										<td>
										<CheckBox checked={selectedLoans.includes(loan._id)} handleCheck={()=>selectLoan(loan._id)}/>
									</td>
									)}
									<td>
										<p>{1 + i}</p>
									</td>
									<td>
										<p>{loan?.institution?.name ? loan?.institution?.name : "Not Provided"}</p>
									</td>

									<td>
										<p ml="10px" mb="-4px">
											{loan?.institution?.faculty ? loan?.institution?.faculty : "Not Provided"}
										</p>
									</td>
									<td>
										<p ml="10px" mb="-4px">
											{loan?.institution?.course ? loan?.institution?.course : "Not Provided"}
										</p>
									</td>
									<td>
										<p ml="10px" mb="-4px">
											{loan?.loan_type ? loan?.loan_type : "Not Provided"}
										</p>
									</td>
									<td>
										<p ml="10px" mb="-4px">
											{loan?.bank_account?.account_name ? loan?.bank_account?.account_name : "Not Provided"}
										</p>
									</td>
									<td>
										<p ml="10px" mb="-4px">
											{loan?.bank_account?.account_number ? loan?.bank_account?.account_number : "Not Provided"}
										</p>
									</td>
									<td>
										<p ml="10px" mb="-4px">
											{loan?.bank_account?.bank_name ? loan?.bank_account?.bank_name : "Not Provided"}
										</p>
									</td>

									<td>₦{CommaNumber(loan?.amount ? loan?.amount : 0)}</td>
									<td>
										<button style={{cursor:"pointer"}} onClick={() => showDetails(loan)}>Preview</button>
									</td>
									<td>
										<button style={{cursor:"pointer"}} onClick={()=>goToLoanDetails(loan.id)}>See Details</button>
									</td>
								</tr>
							))}

							{filteredLoan?.length > 0 ? (
								<tr className="bottom-header">
									<th className="bottom-header">Total</th>
									<th className="bottom-header">
										<Div display="flex" alignI="center"></Div>
									</th>
									<th className="bottom-header">
										<Div display="flex" alignI="center"></Div>
									</th>

									<th className="bottom-header">
										<Div display="flex" alignI="center"></Div>
									</th>
									<th className="bottom-header">
										<Div display="flex" alignI="center"></Div>
									</th>
									<th className="bottom-header">
										<Div display="flex" alignI="center"></Div>
									</th>
									<th className="bottom-header">
										<Div display="flex" alignI="center"></Div>
									</th>
									<th className="bottom-header">
										<Div display="flex" alignI="center"></Div>
									</th>

									<th className="bottom-header"></th>
									<th className="bottom-header"></th>
									<th className="bottom-header"></th>
									<th className="bottom-header">₦{CommaNumber(amountTotal)}</th>
								</tr>
							) : null}
						</TransTableBody>
					</TransTableContent>
					{/* {tableData?.length <= 0 ? <EmptyDataUi /> : null} */}
				</TransTableContainer>
			</TransTableContent>
		</>
	)
}

export default LoanAppTable
