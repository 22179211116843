import { message, Spin } from "antd"
import useAdminData from "hooks/useAdminData"
import { useState } from "react"
import { addAcceptMemoThread, addRaiseMemoThread, addRejectMemoThread, } from "services/AdminUserService"


export function ActionButton({clickHandler,hidden,disabled,loading,children,btnType,className}){
    const btnClass = (function getBtnClass(){
        if(btnType === "accept") return "bg-green-300  text-green-800"
        return "bg-indigo-300  text-indigo-800"
    })()
    if(hidden) return null
    return (
        <button onClick={clickHandler} disabled={disabled || loading} className={`px-4 ${btnClass} ${className} py-1 rounded-md disabled:opacity-60 disabled:cursor-not-allowed font-medium`}>
        {
           loading
           ?<Spin/>
           :<>
              {children}
           </>
       }
       </button>
    )
}

export default function ForwardMemo({memo_id,sender,receiver,selectedLoans}){

    const [loading,setLoading] = useState(false)
    const [rejectLoading,setRejectLoading] = useState(false)
    const [raisingMemo,setRaisingMemo] = useState(false)
    const {adminRole,userProfile:admin} = useAdminData()
    const [note,setNote] = useState("")

    async function rejectMemo(){
        if(selectedLoans.length < 1) {
            message.warning("Please you should have atleast one loan in this memo")
            return;
        }
        if (adminRole === "la") {
            // console.log(userProfile)
            const payload = { note,status:"rejected",memo_id,receiver}
            setRejectLoading(true)
            const res = await addRejectMemoThread(payload)
            setRejectLoading(false)
            if (!res.error) {
                setNote("")
            }
        }

     }
     async function acceptMemo(){
        console.log(adminRole)
        if(selectedLoans.length < 1) {
            message.warning("Please you should have atleast one loan in this memo")
            return;
        }
        if (adminRole === "la") {
            const payload = { note,status:"accepted",memo_id,receiver,selectedLoans}
            setLoading(true)
            const res = await addAcceptMemoThread(payload)
            setLoading(false)

            if (!res.error) {
                setNote("")
            }
        }

     }
     async function raiseMemo(){
        if(selectedLoans.length < 1) {
            message.warning("Please you should have atleast one loan in this memo")
            return;
        }
        if (adminRole === "lph") {
            const payload = { note,status:"accepted",memo_id,receiver,selectedLoans}
            setRaisingMemo(true)
            const res = await addRaiseMemoThread(payload)
            setRaisingMemo(false)

            if (!res.error) {
                setNote("")
            }
        }

     }
    if(adminRole === "es") return null;
    return (
        <div >
                {
                    admin._id !== sender || adminRole !== "lph"
                    ?(<textarea value={note} onChange={(e)=>setNote(e.target.value)} placeholder="Add a note to your memo" className="resize-none p-2 min-h-[70px] rounded-md bg-gray-200 w-full"/>)
                    :null
                }
                <div className="flex gap-2 justify-end items-center">
                    <ActionButton hidden={admin._id === sender || adminRole === "lph"} disabled={rejectLoading} loading={loading} btnType={"accept"} clickHandler={acceptMemo}>
                          <span><i className="bi bi-check2-circle text-lg"></i></span>
                          <span>Accept Memo</span>
                    </ActionButton> 
                    <ActionButton hidden={admin._id === sender || adminRole === "lph" } loading={rejectLoading} disabled={loading} clickHandler={rejectMemo}>
                        <span><i className="bi bi-reply-fill text-lg"></i></span>
                        <span>Query Memo</span>
                    </ActionButton>
                    <ActionButton hidden={admin._id === sender || adminRole !== "lph"} loading={raisingMemo} clickHandler={raiseMemo}>
                        <span><i className="bi bi-reply-fill text-lg"></i></span>
                        <span>Raise Memo</span>
                    </ActionButton>
                </div>
        </div>
    )
}