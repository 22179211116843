import MainLayout from "layout/MainLayout";
import SuperuserTable from "./SuperuserTable";
import { Link, useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE_SUPERUSER } from "routes";
import useAdminData from "hooks/useAdminData";
import { useEffect, useState } from "react";
import FullScreenLoader from "components/Dashboard/FullScreenLoader";
import SuperUserService from "./SuperUserService";

export default function SuperUserPage(){
    const navigate = useNavigate()
    const {adminRole,isLoading,userProfile} = useAdminData()
    const [dataSource,setDataSource] = useState([])
    const [loading,setLoading]  = useState(false)

    const refetch = async() => {
        setLoading(true)
        const [data,error] = await SuperUserService.getLoans()
        setLoading(false)
        console.log(error)
        if(data){
            setDataSource(data?.data)
        }
    }
    useEffect(()=>{
        if(!isLoading){
            if(userProfile && adminRole !== "es"){
                return navigate("/");
            }
            if(userProfile && !userProfile.isSuperuser){
                return navigate("/");
            }
            const getLoans = async() => {
                setLoading(true)
                const [data,error] = await SuperUserService.getLoans()
                console.log(data)
                setLoading(false)
                console.log(error)
                if(data){
                    console.log(data)
                    setDataSource(data?.data)
                }
            }
            getLoans()
        }
    },[isLoading,adminRole,navigate,userProfile])
    if(isLoading){
        return <FullScreenLoader/>
    }
    return (
        <MainLayout>
            <div className="p-4">
                <h1 className="text-4xl my-8 mb-12 font-semibold text-[#333333]">Superuser Loans</h1>
                <div className="my-3">
                    <header className="flex justify-end mb-4">
                        <Link to={`${DASHBOARD_ROUTE_SUPERUSER}/add`}>
                            <button className="px-6 py-1di border border-solid border-primary-300 text-primary font-bold">
                                <span><bi className="bi-plus-lg text-2xl mr-2"></bi></span>
                                <span>Add New</span>
                            </button>
                        </Link>
                    </header>
                    <SuperuserTable loading={loading} refetch={refetch} dataSource={dataSource}/>
                </div>
            </div>
        </MainLayout>
    )
}